export const LebwcoPatientInformation = ({ data }) => {
    return (
        <div style={{ textAlign: 'center', paddingBottom: 10 }}>
            <div style={{ fontSize: 28 }}>
                <b>Donor Id:</b> {data?.patient?.patient_identifier}
            </div>
            <div style={{ fontSize: 18, padding: 10 }}>
                <b>Recovery Date:</b> {new Date(data?.patient?.created_at)?.toLocaleDateString('en-US')}
            </div>
            <div style={{ fontSize: 18 }}>
                <b>Recovery Technician:</b> {`${data?.creator?.first_name} ${data?.creator?.middle_name || ''} ${data?.creator?.last_name}`}
            </div>
        </div>
    )
}