import { Card, Checkbox, Space, Input, Divider, Radio, notification, Alert } from "antd";
import { DeleteFilled } from '@ant-design/icons';
import { ExamFindingsTitleWrapper, ExamFindingsWrapper, ExamFindingsSection, ExamFiningsNotificationSection } from './style';
import { useState, useEffect, useRef } from "react";
import { CONSTANT, getFromLocalStorage, Server } from "../../../../Utils";
import { Services } from "../../../../__services";

const conjunctivaList1 = ['Normal', 'Edema', 'Jaundice', 'Laceration'];
const conjunctivaList2 = ['Pterygium', 'Petechiae', 'Discharge', 'Other'];

const corneaList1 = ['Normal', 'Arcus', 'Exposure', 'Keratoconus'];
const corneaList2 = ['Scar/opacity', 'Foreign body', 'Other'];

const cornealClarityList = ['Clear', 'Cloudy', 'Opaque'];

const lensList = ['Phakic', 'Pseudophakic', 'Aphakic'];

const warningList = ['No warning signs', 'May not be suitable for transplantation'];

export const ExamFindings = ({ data, submit }) => {
    const [api, contextHolder] = notification.useNotification();

    const [conjunctivaODDiagnosis, setConjuctivaODDiagnosis] = useState([]);
    const [conjunctivaOSDiagnosis, setConjuctivaOSDiagnosis] = useState([]);
    const [corneaODDiagnosis, setCorneaODDiagnosis] = useState([]);
    const [corneaOSDiagnosis, setCorneaOSDiagnosis] = useState([]);
    const [cornealClarityODDiagnosis, setCornealClarityODDiagnosis] = useState([]);
    const [cornealClarityOSDiagnosis, setCornealClarityOSDiagnosis] = useState([]);
    const [lensStatusODDiagnosis, setLensStatusODDiagnosis] = useState([]);
    const [lensStatusOSDiagnosis, setLensStatusOSDiagnosis] = useState([]);
    const [warningODDiagnosis, setWarningODDiagnosis] = useState([]);
    const [warningOSDiagnosis, setWarningOSDiagnosis] = useState([]);
    const [notesODDiagnosis, setNotesODDiagnosis] = useState([]);
    const [notesOSDiagnosis, setNotesOSDiagnosis] = useState([]);

    const [conjunctivaOD, setConjuctivaOD] = useState([]);
    const [otherConjunctivaOD, setOtherConjunctivaOD] = useState();
    const [conjunctivaOS, setConjuctivaOS] = useState([]);
    const [otherConjunctivaOS, setOtherConjunctivaOS] = useState();
    const [corneaOD, setCorneaOD] = useState([]);
    const [otherCorneaOD, setOtherCorneaOD] = useState();
    const [corneaOS, setCorneaOS] = useState([]);
    const [otherCorneaOS, setOtherCorneaOS] = useState();
    const [cornealClarityOD, setCornealClarityOD] = useState();
    const [cornealClarityOS, setCornealClarityOS] = useState();
    const [lensStatusOD, setLensStatusOD] = useState();
    const [lensStatusOS, setLensStatusOS] = useState();
    const [warningOD, setWariningOD] = useState();
    const [warningOS, setWariningOS] = useState();
    const [notesOD, setNotesOD] = useState();
    const [notesOS, setNotesOS] = useState();

    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current) {
            getExamFindings();
            isFirstRender.current = false
            return;
        }

        if (submit) {
            submitExamFindings();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onChangeHandler = (type, value) => {
        if (type === 'conjunctivaOD') {
            setConjuctivaOD(value);
        } else if (type === 'otherConjunctivaOD') {
            setOtherConjunctivaOD(value);
        } else if (type === 'conjunctivaOS') {
            setConjuctivaOS(value);
        } else if (type === 'otherConjunctivaOS') {
            setOtherConjunctivaOS(value);
        } else if (type === 'corneaOD') {
            setCorneaOD(value);
        } else if (type === 'otherCorneaOD') {
            setOtherCorneaOD(value);
        } else if (type === 'corneaOS') {
            setCorneaOS(value);
        } else if (type === 'otherCorneaOS') {
            setOtherCorneaOS(value);
        } else if (type === 'cornealClarityOD') {
            setCornealClarityOD(value);
        } else if (type === 'cornealClarityOS') {
            setCornealClarityOS(value);
        } else if (type === 'lensStatusOD') {
            setLensStatusOD(value);
        } else if (type === 'lensStatusOS') {
            setLensStatusOS(value);
        } else if (type === 'warningOD') {
            setWariningOD(value);
        } else if (type === 'warningOS') {
            setWariningOS(value);
        } else if (type === 'notesOD') {
            setNotesOD(value);
        } else if (type === 'notesOS') {
            setNotesOS(value);
        }

    }

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteExamFindings(value, path);
        }
    }

    const getExamFindings = async () => {
        setConjuctivaODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCONJUNCTIVAOD) || []);
        setConjuctivaOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCONJUNCTIVAOS) || []);
        setCorneaODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEAOD) || []);
        setCorneaOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEAOS) || []);
        setCornealClarityODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEALCLARITYOD) || []);
        setCornealClarityOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEALCLARITYOS) || []);
        setLensStatusODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSLENSSTATUSOD) || []);
        setLensStatusOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSLENSSTATUSOS) || []);
        setWarningODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSWARNINGOD) || [])
        setWarningOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSWARNINGOS) || []);
        setNotesODDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSNOTESOD) || []);
        setNotesOSDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSNOTESOS) || []);
    }

    const submitExamFindings = async () => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (conjunctivaOD) {
            let other = conjunctivaOD.indexOf('Other');
            if (other !== -1) {
                conjunctivaOD.splice(other, 1);
                conjunctivaOD.push(otherConjunctivaOD);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCONJUNCTIVAOD,
                obs_string: {
                    value: conjunctivaOD.join(', ')
                }
            })
        }
        if (conjunctivaOS.length) {
            let other = conjunctivaOS.indexOf('Other');
            if (other !== -1) {
                conjunctivaOS.splice(other, 1);
                conjunctivaOS.push(otherConjunctivaOS);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCONJUNCTIVAOS,
                obs_string: {
                    value: conjunctivaOS.join(', ')
                }
            })
        }
        if (corneaOD.length) {
            let other = corneaOD.indexOf('Other');
            if (other !== -1) {
                corneaOD.splice(other, 1);
                corneaOD.push(otherCorneaOD);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCORNEAOD,
                obs_string: {
                    value: corneaOD.join(', ')
                }
            })
        }
        if (corneaOS.length) {
            let other = corneaOS.indexOf('Other');
            if (other !== -1) {
                corneaOS.splice(other, 1);
                corneaOS.push(otherCorneaOS);
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCORNEAOS,
                obs_string: {
                    value: corneaOS.join(', ')
                }
            })
        }
        if (cornealClarityOD) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCORNEALCLARITYOD,
                obs_string: {
                    value: cornealClarityOD
                }
            })
        }
        if (cornealClarityOS) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSCORNEALCLARITYOS,
                obs_string: {
                    value: cornealClarityOS
                }
            })
        }
        if (lensStatusOD) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSLENSSTATUSOD,
                obs_string: {
                    value: lensStatusOD
                }
            })
        }
        if (lensStatusOS) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSLENSSTATUSOS,
                obs_string: {
                    value: lensStatusOS
                }
            })
        }
        if (warningOD) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSWARNINGOD,
                obs_string: {
                    value: warningOD
                }
            })
        }
        if (warningOS) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSWARNINGOS,
                obs_string: {
                    value: warningOS
                }
            })
        }
        if (notesOD) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSNOTESOD,
                obs_string: {
                    value: notesOD
                }
            })
        }
        if (notesOS) {
            allData.push({
                ...payload,
                concept_id: CONSTANT.EXAMFINDINGSNOTESOS,
                obs_string: {
                    value: notesOS
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    let conjOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCONJUNCTIVAOD);
                    let conjOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCONJUNCTIVAOS);
                    let cornOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEAOD);
                    let cornOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEAOS);
                    let cornCOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEALCLARITYOD);
                    let cornCOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSCORNEALCLARITYOS);
                    let lensOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSLENSSTATUSOD);
                    let lensOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSLENSSTATUSOS);
                    let warnOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSWARNINGOD);
                    let warnOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSWARNINGOS);
                    let notesOD = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSNOTESOD);
                    let notesOS = response.filter(ob => ob.concept_id === CONSTANT.EXAMFINDINGSNOTESOS);
                    if (conjOD.length) {
                        setConjuctivaODDiagnosis([...conjunctivaODDiagnosis, ...conjOD]);
                    }
                    if (conjOS.length) {
                        setConjuctivaOSDiagnosis([...conjunctivaOSDiagnosis, ...conjOS]);
                    }
                    if (cornOD.length) {
                        setCorneaODDiagnosis([...corneaODDiagnosis, ...cornOD]);
                    }
                    if (cornOS.length) {
                        setCorneaOSDiagnosis([...corneaOSDiagnosis, ...cornOS]);
                    }
                    if (cornCOD.length) {
                        setCornealClarityODDiagnosis([...cornealClarityODDiagnosis, ...cornCOD]);
                    }
                    if (cornCOS.length) {
                        setCornealClarityOSDiagnosis([...cornealClarityOSDiagnosis, ...cornCOS]);
                    }
                    if (lensOD.length) {
                        setLensStatusODDiagnosis([...lensStatusODDiagnosis, ...lensOD]);
                    }
                    if (lensOS.length) {
                        setLensStatusOSDiagnosis([...lensStatusOSDiagnosis, ...lensOS]);
                    }
                    if (warnOD.length) {
                        setWarningODDiagnosis([...warningODDiagnosis, ...warnOD]);
                    }
                    if (warnOS.length) {
                        setWarningOSDiagnosis([...warningOSDiagnosis, ...warnOS]);
                    }
                    if (notesOD.length) {
                        setNotesODDiagnosis([...notesODDiagnosis, ...notesOD]);
                    }
                    if (notesOS.length) {
                        setNotesOSDiagnosis([...notesOSDiagnosis, ...notesOS]);
                    }
                    resetField();
                    openNotificationWithIcon('success', 'Diagnosis Submitted');
                }
            })
        } else {
            // openNotificationWithIcon('error', 'No Diagnosis');
        }
    }

    const deleteExamFindings = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'conjunctivaODDiagnosis') {
                    let newData = [...conjunctivaODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setConjuctivaODDiagnosis(newData);
                    }
                } else if (path === 'conjunctivaOSDiagnosis') {
                    let newData = [...conjunctivaOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setConjuctivaOSDiagnosis(newData);
                    }
                } else if (path === 'corneaODDiagnosis') {
                    let newData = [...corneaODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setCorneaODDiagnosis(newData);
                    }
                } else if (path === 'corneaOSDiagnosis') {
                    let newData = [...corneaOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setCorneaOSDiagnosis(newData);
                    }
                } else if (path === 'cornealClarityODDiagnosis') {
                    let newData = [...cornealClarityODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setCornealClarityODDiagnosis(newData);
                    }
                } else if (path === 'cornealClarityOSDiagnosis') {
                    let newData = [...cornealClarityOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setCornealClarityOSDiagnosis(newData);
                    }
                } else if (path === 'lensStatusODDiagnosis') {
                    let newData = [...lensStatusODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLensStatusODDiagnosis(newData);
                    }
                } else if (path === 'lensStatusOSDiagnosis') {
                    let newData = [...lensStatusOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLensStatusOSDiagnosis(newData);
                    }
                } else if (path === 'warningODDiagnosis') {
                    let newData = [...warningODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setWarningODDiagnosis(newData);
                    }
                } else if (path === 'warningOSDiagnosis') {
                    let newData = [...warningOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setWarningOSDiagnosis(newData);
                    }
                } else if (path === 'notesODDiagnosis') {
                    let newData = [...notesODDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setNotesODDiagnosis(newData);
                    }
                } else if (path === 'notesOSDiagnosis') {
                    let newData = [...notesOSDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setNotesOSDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'Diagnosis Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setConjuctivaOD([]);
        setOtherConjunctivaOD('');
        setConjuctivaOS([]);
        setOtherConjunctivaOS('');
        setCorneaOD([]);
        setOtherCorneaOD('');
        setCorneaOS([])
        setOtherCorneaOS('');
        setCornealClarityOD();
        setCornealClarityOS('');
        setLensStatusOD('');
        setLensStatusOS('');
        setWariningOD('');
        setWariningOS('');
        setNotesOD('');
        setNotesOS('');
    }

    return (
        <div>
            <Card title={(<>Exam Findings</>)}>
                <ExamFindingsTitleWrapper>
                    <div>OD</div>
                    <div>OS</div>
                </ExamFindingsTitleWrapper>
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Conjunctiva/Sclera:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Checkbox.Group onChange={(e) => onChangeHandler('conjunctivaOD', e)} value={conjunctivaOD} style={{ width: '50%' }}>
                            <Space direction="vertical">
                                {conjunctivaList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                            </Space>
                            <Space direction="vertical">
                                {conjunctivaList2?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                {conjunctivaOD.includes('Other') ? <Input placeholder="Name" value={otherConjunctivaOD} onChange={(e) => onChangeHandler('otherConjunctivaOD', e.target.value)} /> : null}
                            </Space>
                        </Checkbox.Group>
                    </ExamFindingsSection>

                    <ExamFindingsSection>
                        <Checkbox.Group onChange={(e) => onChangeHandler('conjunctivaOS', e)} value={conjunctivaOS} style={{ width: '50%' }}>
                            <Space direction="vertical">
                                {conjunctivaList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                            </Space>
                            <Space direction="vertical">
                                {conjunctivaList2?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                {conjunctivaOS.includes('Other') ? <Input placeholder="Name" value={otherConjunctivaOS} onChange={(e) => onChangeHandler('otherConjunctivaOS', e.target.value)} /> : null}
                            </Space>
                        </Checkbox.Group>
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {conjunctivaODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'conjunctivaODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {conjunctivaOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'conjunctivaOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>



                <Divider />
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Cornea:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Checkbox.Group onChange={(e) => onChangeHandler('corneaOD', e)} value={corneaOD} style={{ width: '50%' }}>
                            <Space direction="vertical">
                                {corneaList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                            </Space>
                            <Space direction="vertical">
                                {corneaList2?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                {corneaOD.includes('Other') ? <Input placeholder="Name" value={otherCorneaOD} onChange={(e) => onChangeHandler('otherCorneaOD', e.target.value)} /> : null}
                            </Space>
                        </Checkbox.Group>
                    </ExamFindingsSection>

                    <ExamFindingsSection>
                        <Checkbox.Group onChange={(e) => onChangeHandler('corneaOS', e)} value={corneaOS} style={{ width: '50%' }}>
                            <Space direction="vertical">
                                {corneaList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                            </Space>
                            <Space direction="vertical">
                                {corneaList2?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                {corneaOS.includes('Other') ? <Input placeholder="Name" value={otherCorneaOS} onChange={(e) => onChangeHandler('otherCorneaOS', e.target.value)} /> : null}
                            </Space>
                        </Checkbox.Group>
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {corneaODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'corneaODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {corneaOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'corneaOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>

                <Divider />
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Corneal Clarity:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Radio.Group value={cornealClarityOD} onChange={(e) => onChangeHandler('cornealClarityOD', e.target.value)}>
                            <Space direction="vertical">
                                {cornealClarityList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>

                    <ExamFindingsSection>
                        <Radio.Group value={cornealClarityOS} onChange={(e) => onChangeHandler('cornealClarityOS', e.target.value)}>
                            <Space direction="vertical">
                                {cornealClarityList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {cornealClarityODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'cornealClarityODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {cornealClarityOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'cornealClarityOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>

                <Divider />
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Lens Status:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Radio.Group value={lensStatusOD} onChange={(e) => onChangeHandler('lensStatusOD', e.target.value)}>
                            <Space direction="vertical">
                                {lensList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>

                    <ExamFindingsSection>
                        <Radio.Group value={lensStatusOS} onChange={(e) => onChangeHandler('lensStatusOS', e.target.value)}>
                            <Space direction="vertical">
                                {lensList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {lensStatusODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'lensStatusODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {lensStatusOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'lensStatusOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>

                <Divider />
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Are these any warning signs that the cornea may not be suitable for transplantation:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Radio.Group value={warningOD} onChange={(e) => onChangeHandler('warningOD', e.target.value)}>
                            <Space direction="vertical">
                                {warningList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>

                    <ExamFindingsSection>
                        <Radio.Group value={warningOS} onChange={(e) => onChangeHandler('warningOS', e.target.value)}>
                            <Space direction="vertical">
                                {warningList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {warningODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'warningODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {warningOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'warningOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>

                <Divider />
                <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 15 }}>Notes:</div>
                <ExamFindingsWrapper>
                    <ExamFindingsSection>
                        <Input value={notesOD} onChange={(e) => onChangeHandler('notesOD', e.target.value)} />
                    </ExamFindingsSection>
                    <ExamFindingsSection>
                        <Input value={notesOS} onChange={(e) => onChangeHandler('notesOS', e.target.value)} />
                    </ExamFindingsSection>
                </ExamFindingsWrapper>

                <ExamFindingsWrapper>
                    <ExamFiningsNotificationSection>
                        {notesODDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'notesODDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                    <ExamFiningsNotificationSection>
                        {notesOSDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'notesOSDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </ExamFiningsNotificationSection>
                </ExamFindingsWrapper>
            </Card>
            {contextHolder}
        </div>
    )
}