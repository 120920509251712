import { Button } from 'antd';
import { Layout, Menu } from 'antd';
import { NavigationWrapper } from './style';
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "../../Pages/ContextProvider";
import { UploadImage } from '../../Pages/UploadImage';
import { PatientSearch } from '../../Pages/PatientSearch';
import { Server } from '../../Utils';

const { Header, Content, Footer } = Layout;
const menuItems = [
    {
        label: 'Data entry',
        key: 'home',
    },
    {
        label: 'Patient Search',
        key: 'search'
    },
    {
        label: 'Upload Images',
        key: 'image'
    },
    {
        label: 'Admin',
        key: 'admin'
    }
]

const menuitems2 = [
    {
        label: 'Home',
        key: 'home',
    },
    {
        label: 'Patient Search',
        key: 'search'
    },
    {
        label: 'Admin',
        key: 'admin'
    },
]


export const LayoutPage = ({ children }) => {
    const { state: { page }, updateState } = useContext(Context);
    const [showImageUpload, setShowImageUpload] = useState(false);
    const [showPatientSearch, setShowPatientSearch] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onClickHandler = (type, value) => {
        if (type === 'logout') {
            updateState('user', null)
            navigate('/')
        } else if (type === 'menu') {
            if (value.key === 'home') {
                updateState('page', 'user');
                navigate('/home', { state: location.state });
            } else if (value.key === 'admin') {
                updateState('page', '/admin');
                navigate('/admin/dashboard', { state: location.state })
            } else if (value.key === 'image') {
                setShowImageUpload(true);
            } else if (value.key === 'search') {
                setShowPatientSearch(true);
            } else {
                console.log(value)
            }
        } else if (type === 'showPatientSearch') {
            setShowPatientSearch(value);
        } else if (type === 'showImageUpload') {
            setShowImageUpload(false);
        }
    }


    return (
        <>
            <Layout style={{ minHeight: "100vh" }}>
                <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                    <NavigationWrapper>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
                            <Menu theme="dark" mode="horizontal" style={{ width: Server.ENV.name === 'lebwco' ? '300px' : '445px' }} items={Server.ENV.name === 'lebwco' ? menuitems2 : menuItems} onClick={(e) => onClickHandler('menu', e)} />
                            <Button type="primary" danger onClick={() => onClickHandler('logout')}>Logout</Button>
                        </div>
                    </NavigationWrapper>
                </Header>
                <Content>
                    <div style={{ padding: page !== '/admin' ? '24px 100px' : '' }}>
                        {children}
                    </div>
                </Content>
                {page !== '/admin' ?
                    <Footer style={{ textAlign: 'center', position: 'sticky', bottom: '0', padding: '10px' }}>Visilant ©2024</Footer>
                    : null}
            </Layout>
            {showImageUpload ? <UploadImage onClick={onClickHandler} /> : null}
            {showPatientSearch ? <PatientSearch onClick={onClickHandler} /> : null}
        </>
    )
}