import { Card } from "antd";
import { CONSTANT, Server } from "../../../../Utils";
import { PatientImage } from './PatientImage';

export const OnExamination = ({ data }) => {
    let { obs } = data
    return (
        <div>
            {Server.ENV.name !== 'lebwco' ? <Card title={(<>On Examination</>)}>
                <table width={'100%'}>
                    <thead style={{ textAlign: "justify" }}>
                        <tr>
                            <th></th>
                            <th>Right Eye</th>
                            <th>Left Eye</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b>Complaints</b></td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.COMPLAINTRIGHT && dt?.obs_string?.value)}</td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.COMPLAINTLEFT && dt?.obs_string?.value)}</td>
                        </tr>
                        <tr>
                            <td><b>Visual Acuity</b></td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.VARIGHT && dt?.obs_string?.value)}</td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.VALEFT && dt?.obs_string?.value)}</td>
                        </tr>
                        <tr>
                            <td><b>Pinhole Acuity</b></td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.PINEHOLERIGHT && dt?.obs_string?.value)}</td>
                            <td>{obs.map(dt => dt.concept_id === CONSTANT.PINEHOLELEFT && dt?.obs_string?.value)}</td>
                        </tr>
                    </tbody>
                </table>
            </Card> : null}
            <Card title={Server.ENV.name === 'lebwco' ? (<>Eye images</>) : null}>
                <PatientImage data={data} />
            </Card>
        </div>
    )
}