import styled from "styled-components";

export const ExamFindingsTitleWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    font-weight: 900;
    text-decoration: underline;
`;

export const ExamFindingsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ExamFindingsSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
`;

export const ExamFiningsNotificationSection = styled.div`
    width: 45%
`;