import { useContext, useEffect, useRef } from "react";
import { Context } from "../ContextProvider";
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import { Card, Input, Space, Button, notification } from 'antd';
import { LoginWrapper, CardWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { Services } from '../../__services';
import { CONSTANT, getFromLocalStorage, setCookie, setToken, EncryptionString, getCookie, DecryptionString, saveToLocalStorage, getIP } from "../../Utils";
import VisilantLogo from '../../Component/Images/VisilantLogo.png';

let access = [CONSTANT.USER, CONSTANT.SYSTEMADMIN, CONSTANT.DOCTOR];

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const Login = () => {
    const [api, contextHolder] = notification.useNotification();
    const { updateState } = useContext(Context);
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    useEffect(() => {
        const checkUser = async () => {
            let user = getFromLocalStorage('user');
            if (user) {
                updateState('page', '/home');
                navigate('/home', { state: { user_type: user.person_type_id === CONSTANT.USER ? 'user' : 'coordinator' } });
            }
        }
        checkUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = async (type) => {
        if (type === 'login') {
            if (emailRef.current.input.value && passwordRef.current.input.value) {
                let payload = {
                    password: passwordRef.current.input.value
                }
                if (validateEmail(emailRef.current.input.value)) {
                    payload.email = emailRef.current.input.value
                } else {
                    payload.username = emailRef.current.input.value
                }
                let params = `user=${emailRef.current.input.value}`;
                let getLogin = await Services.GetFailedLogin(params);
                if (getLogin?.rows?.length) {
                    let { id, value } = getLogin?.rows[0];
                    if (value === 2) {
                        openNotificationWithIcon('info', 'Incorrect password. You have {3} more log-in attempts before the account is locked for {5 minutes}. Please contact your system administrator for additional assistance.');
                        saveFailedLogin(id, value + 1);
                        return;
                    }
                    if (value === 5) {
                        let getLockTime = getFromLocalStorage('lockedTime')
                        if (getLockTime) {
                            if (getLockTime > new Date().getTime()) {
                                openNotificationWithIcon('info', 'Account is locked for 5 mins')
                                return;
                            } else {
                                Login(payload, getLogin);
                                return;
                            }
                        } else {
                            openNotificationWithIcon('info', 'Account is locked for 5 mins');
                            saveToLocalStorage('lockedTime', new Date().getTime() + 5 * 60000)
                        }
                        return;
                    }
                    if ((value > 5 && value < 10) || (value > 10 && value < 15)) {
                        localStorage.removeItem('lockedTime')
                    }
                    if (value === 10) {
                        let getLockTime = getFromLocalStorage('lockedTime');
                        if (getLockTime) {
                            if (getLockTime > new Date().getTime()) {
                                openNotificationWithIcon('info', 'Account is locked for 30 mins')
                                return;
                            } else {
                                Login(payload, getLogin);
                                return;
                            }
                        } else {
                            openNotificationWithIcon('info', 'Account is locked for 30 mins');
                            saveToLocalStorage('lockedTime', new Date().getTime() + 30 * 60000)
                        }
                        return;
                    }
                    if (value === 15) {
                        openNotificationWithIcon('info', 'Contact System Administrator');
                        SendMail(emailRef.current.input.value);
                        Login(payload, getLogin);
                        return;
                    }
                    if (value > 15) {
                        openNotificationWithIcon('info', 'Contact System Administrator');
                        return;
                    }
                    Login(payload, getLogin);
                } else {
                    Login(payload, getLogin);
                    // openNotificationWithIcon('info', 'No user found')
                }
            }
        }
    }

    const saveFailedLogin = async (id, value) => {
        try {
            let payload = {
                user: emailRef.current.input.value,
                value: value,
                ip_details: await getIP()
            }
            Services.PutFailedLogin(id, payload).then(response => { });
        } catch (e) {
            console.log('save failed login: ', e)
        }
    }

    const Login = (payload, getLogin) => {
        try {
            Services.Signin(payload).then(async response => {
                if (response.token) {
                    if (access.includes(response.user.person_type_id)) {
                        updateState('user', response);
                        setCookie('userP', EncryptionString(passwordRef.current.input.value));
                        setToken(response.token);
                        updateState('page', '/home');
                        navigate('/home', { state: { user_type: 'user' } });
                        saveFailedLogin(getLogin?.rows[0]?.id, 0);
                    }
                } else {
                    try {
                        if (response.data.error === 'Invalid Password') {
                            if (getLogin?.rows?.length) {
                                let { id, value } = getLogin?.rows[0];
                                saveFailedLogin(id, value + 1);
                            } else {
                                let payload = {
                                    user: emailRef.current.input.value,
                                    value: 1,
                                    ip_details: await getIP()
                                }
                                Services.PostFailedLogin(payload).then(response => { });
                            }
                        }
                    } catch (err) {
                        console.log('Login failed exception: ', err);
                    }
                    openNotificationWithIcon('error', response.data.error || 'Server Error')
                }
            })
        } catch (e) {
            console.log('Post sign in login exception: ', e);
        }
    }

    const SendMail = (value) => {
        try {
            let payload = {
                user: value
            }
            Services.PostPasswordEmail(payload).then(response => { });
        } catch (e) {
            console.log('Send Mail exception: ', e);
        }
    }

    return (
        <>
            <LoginWrapper>
                <img src={VisilantLogo} width={'20%'} alt="" />
                <CardWrapper>
                    <Card title="Login In" bordered={false}>
                        <Space direction="vertical">
                            <Input size="large" placeholder="Username" prefix={<UserOutlined />} ref={emailRef} />
                            <Input.Password size="large" prefix={<LockOutlined />} ref={passwordRef}
                                placeholder="Password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                            <div style={{ textAlign: 'end' }}>
                                <Button type="primary" onClick={() => onClickHandler('login')}>Login</Button>
                            </div>
                        </Space>
                    </Card>
                </CardWrapper>
            </LoginWrapper>
            {contextHolder}
        </>
    )
}